.ID {
  color: rgb(0, 0, 0);
  padding-left: 20px;
  text-align: left;
  width: 35%;
}

.Data {
  color: rgb(0, 0, 0);
  width: 60%;
  word-wrap: break-word;
  text-align: left;
}

.Row {
  padding: 10px 5px;
  display: flex;
  font-size: 14px;
}

/* Raw Info Area */

.Rawrow {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-align: left;
  padding: 2px;
  margin-left: 20px;
}
.raw-area {
  font-family: "Courier New", Courier, monospace;
  background: #e8e8e8;
}

.id {
  font-weight: bold;
  margin-left: 40px;
  width: 30%;
  color: rgb(75, 75, 75);
}
.data {
  margin-left: 15%;
  width: 60%;
  word-wrap: break-word;
  text-align: left;
  color: rgb(78, 78, 78);
}

.rTl {
  text-align: left;
  margin-left: 20px;
  font-size: 12px;
}

.row-x {
  display: flex;
  background-color: rgb(254, 254, 255);
  border-bottom: 1px solid rgb(185, 185, 185);
}

.row-x > .id {
  font-size: 14px;
  width: 30vw;
  padding: 10px;
  text-align: left;
}
.row-x > .data {
  font-size: 14px;
  width: 20vw;
  padding: 10px;
  margin-left: 10px;
}
.position-x {
  margin: auto;
  margin-top: 50px;
}

.info-body {
  background-color: rgb(208, 214, 214);
  width: 70vw;
  border-radius: 20px;
}

.info-body > .table {
  width: 90%;
  margin: auto;
  padding-bottom: 20px;
}

.info-body > .head {
  font-size: 30px;
  color: rgb(78, 78, 78);
}

