.parentBody {
  width: 90vw;
  margin: auto;
  padding-top: 30px;
}
.top_body {
  width: 100vw;
}

.dns_row {
  padding: 10px;
  font-size: 16px;
  text-align: left;
  border-bottom: 1px solid black;
}

.top_title {
  background-color: rgb(145, 155, 155);
  width: 100%;
}

.name {
  width: 40%;
}
.type {
  width: 10%;
}

.glue {
  width: 10%;
}

.tll {
  width: 10%;
}

.ip {
  width: 20%;
}
.family {
  width: 10%;
}

.ns_heading {
  text-align: left;
  padding-left: 20px;
  font-size: 16px;
  margin-top: 40px;
}

.soa_row {
  display: flex;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid black;
}
.soa_field {
  padding: 10px;
  width: 50%;
}
.soa_value {
  padding: 10px;
  width: 50%;
}

.soa_body {
  width: 90vw;
  margin: auto;
  padding-top: 50px;
}

.soa_title {
  margin-top: 20px;
  background-color: rgb(163, 166, 168);
}
.mx_section {
  width: 90vw;
  margin: auto;
  text-align: center;
}
.mx_body {
  display: flex;
  width: 100%;
  text-align: left;
  border: 1px solid black;
  margin-top: 20px;
  border-bottom: none;
}

.mx_head {
  width: 50%;
}
.mx_info {
  width: 50%;
}

.mx_row {
  padding: 5px;
  border-bottom: 1px solid black;
  width: 100%;
}

.heading {
  margin-top: 40px;
}

.w_row {
  display: flex;
  text-align: left;
  margin: auto;
  padding-top: 10px;
  border-bottom: 1px solid black;
}

.w_row > .name {
  width: 20%;
  padding-left: 5px;
}

.w_body {
  width: 90vw;
  margin: auto;
  padding-bottom: 50px;
}

.top {
  background-color: rgb(150, 167, 167);
  padding-bottom: 10px;
}
