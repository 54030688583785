@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Open+Sans&family=Ubuntu+Mono:wght@700&display=swap");
html,
body {
  height: 100%;
}

.App {
  text-align: center;
  background-color: #f4f4f2;
}
.main {
  min-height: calc(100vh - 70px - 70px);
}
.content {
  height: 100%;
  width: 100%;
}

.home {
  width: 100%;
}
.header {
  font-family: "Ubuntu Mono", monospace;
  margin-top: 0;
  margin-bottom: 20px;
  padding-top: 20vh;
  font-size: 75px;
  color: #495464;
}

/* NavBar CSS */
.Nav {
  background-color: #fffdf6;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
}
.logo {
  color: #494949;
}
.bg-x {
  height: 100%;
}
.col-x {
  color: #494949;
}

/* Footer Css */

.footer {
  width: 100%;
  display: flex;
  height: 70px;
  align-items: center;
  background-color: #fffdf6;
  color: #bbbfca;
}

.loading > .cssfx-twin-spin {
  margin: auto;
}

.loading {
  padding-top: 200px;
}
